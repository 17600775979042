/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../components/layout';
import Banner from '../components/Sections/Banner';
import SignUp from '../components/SignUp';

const Register = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SignUp />
      <Banner hideRegister={true}/>
    </Layout>
  );
};

export default Register;
