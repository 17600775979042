import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import PrimaryLink from '../Buttons/PrimaryLink';
import { H15 } from '../Texts';

import image3 from '../../images/use-3.png';

const SignUp = () => {
  const intl = useIntl();
  return (
    <div
      id="sign-up"
      className="max-w-7xl mx-auto lg:pb-60 relative overflow-hidden grid grid-cols-1 lg:grid-cols-2"
    >
      <div className="pt-16 px-16 lg:pl-48 col-span-1">
        <H15 className="mb-2">{intl.formatMessage({ id: 'signUp.title' })}</H15>
        <p className="mb-8">
          {intl.formatMessage({ id: 'signUp.description' })}
        </p>

        <div className="mb-12">
          <p className="text-lg text-gray-700 mb-2 font-medium">
            {intl.formatMessage({ id: 'signUp.farmers.title' })}
          </p>
          <p className="mb-4">
            {intl.formatMessage({ id: 'signUp.farmers.description' })}
          </p>
          <div className="flex lg:space-x-3 flex-col lg:flex-row space-y-3 lg:space-y-0">
            <PrimaryLink
              to={
                process.env.BASE_APP_URL +
                intl.formatMessage({ id: 'signUp.farmers.linkUrl' })
              }
              text={intl.formatMessage({ id: 'signUp.farmers.linkText' })}
              isSmall
            />
          </div>
        </div>

        <hr className="mb-12" />

        <div className="mb-12">
          <p className="text-lg text-gray-700 mb-2 font-medium">
            {intl.formatMessage({ id: 'signUp.merchants.title' })}
          </p>
          <p className="mb-4">
            {intl.formatMessage({ id: 'signUp.merchants.description' })}
          </p>
          <div className="flex lg:space-x-3 flex-col lg:flex-row space-y-3 lg:space-y-0">
            <PrimaryLink
              to={
                process.env.BASE_APP_URL +
                intl.formatMessage({ id: 'signUp.merchants.linkUrl' })
              }
              text={intl.formatMessage({ id: 'signUp.merchants.linkText' })}
              isSmall
            />
          </div>
        </div>
      </div>
      <div className="lg:pr-16 col-span-1">
        <img src={image3} alt="AgroToken" className="" />
      </div>
    </div>
  );
};
export default SignUp;
